<template >
      <b-card class="mt-4 mb-4">

    <div class='container mt-4'>
      <validation-observer ref="editUserFormValidation"></validation-observer>
        <b-form @submit.prevent>

          <b-form-row>
            <b-col md="3" sm="10">
                <b-form-row>
                  <b-col md="12">
                    <b-img fluid :src="profileImage" rounded="circle" :alt="userName"></b-img>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col md="12">

                    <b-form-group
                      class="form-group mt-2"
                      id="input-group-2"
                      label="Change Image:"
                      label-for="input-2"
                    >
                      <b-form-file
                        id="documents"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        required
                        accept=".png"
                        plain
                        v-model="profileImageNew"
                      ></b-form-file>
                </b-form-group>
              </b-col>
              </b-form-row>
            </b-col>

            <b-col md="4" sm="10">
              <b-form-row>
                <b-col>
                    <b-form-group label-for="name">
                    <template #label>
                        Name <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Name"
                    >
                        <b-form-input
                        id="name"
                        placeholder="Name"
                        v-model="name"
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                  <b-form-group label-for="username">
                      <template #label>
                          Username <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                          name="Username"
                      >
                          <b-form-input
                          id="username"
                          placeholder="Username"
                          v-model="userName"
                          disabled
                          />
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                  <b-col>
                    <b-form-group label-for="email">
                    <template #label>
                        Email <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Email"
                    >
                        <b-form-input
                        id="email"
                        placeholder="Email"
                        v-model="email"
                        disabled
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                  
                
                
            </b-col>

              <b-col md="4" sm="10">
                <b-form-row>
                  <b-col>
                    <b-form-group label-for="mobile">
                    <template #label>
                        Mobile <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Mobile"
                        rules="required|integer|length:11"
                    >
                        <b-form-input
                        id="mobile"
                        placeholder="Mobile"
                        v-model="mobile"
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                

                <b-form-row>
                  <b-col>
                    <b-form-group label-for="cnic">
                    <template #label>
                        CNIC <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="CNIC"
                        rules="required|integer|length:13"
                    >
                        <b-form-input
                        id="cnic"
                        placeholder="CNIC"
                        v-model="cnic"
                        disabled
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group label-for="role">
                    <template #label>
                        Role <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Role"
                    >
                        <b-form-input
                        id="role"
                        placeholder="Role"
                        v-model="role"
                        disabled
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>

              </b-col>
            </b-form-row>
            <b-form-group class="mt-2 text-right" >
              <b-button type="submit"  variant="info" pill @click="validationForm">
                Update
              </b-button>
            </b-form-group>
        </b-form>
    </div>
  </b-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import util from "@/util.js";



export default {
    components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  
  mixins: [util],
  props: {
    user: Object,
  },
 
  data() {
    return {
      required,
      userName: "",
      name: "",
      cnic: "",
      mobile: "",
      email: "",
      profileImage: "",
      role: "",
      profileImageNew: null
    };
  },
  
  async mounted() {
    if (this.getLoggedInUser) {
      this.userName = this.getLoggedInUser.username;
      this.name = this.getLoggedInUser.full_name;
      this.cnic = this.getLoggedInUser.cnic;
      this.mobile = this.getLoggedInUser.mobile;
      this.email = this.getLoggedInUser.email;
      this.role = this.getLoggedInUser.role_data.name
      this.profileImage = this.getLoggedInUser?.profile_image;
    }
  },
  methods: {
    ...mapActions({
      updateUser: "appData/updateUser",
      getRoles: "appData/getRoles",
    }),
  
   updateProfileImage(e){
      this.profileImageNew = e.target.files[0]
   },
  
  async validationForm() {
      const success = await this.$refs.editUserFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append('full_name', this.name);
        formData.append('cnic', this.cnic);
        formData.append('mobile', this.mobile);
        formData.append('email', this.email);
        formData.append('updated_by', this.getLoggedInUser.id);
        if (this.profileImageNew){
          formData.append('profile_image', this.profileImageNew);
        this.profileImageNew = null
        }

        const res = await this.updateUser({
          payload: formData,
          pk: this.getLoggedInUser.id,
        });
        if (res.status === 200) {
          const resData = res.data
          this.name = resData.full_name
          this.mobile = resData.mobile
          this.profile_image = resData.profile_image

          this.$swal({
            title: "User updated successfully",
            icon: "success",
          });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
},
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
  
};
</script>
